###
.d8888b.  88d888b.  88d888b.
88'  `88  88'  `88  88'  `88
88.  .88  88.  .88  88.  .88
`88888P8  88Y888P'  88Y888P'
ooooooooo~88~oooooo~88~oooooo
          dP        dP

@plugin  	Revelate
@author 	Alex Grozav
@website   	http://pixevil.com
@version 	1.0
@license 	Commercial
###

$('document').ready ->
  set_full_width = ->
    $('#kiosk-wrapper').width $(window).width()
    $('#kiosk-wrapper').height $(window).height()
    return
  $(window).resize set_full_width
  set_full_width()


  $('.close-notification').on 'click', (e) =>
    target = $(e.target).closest '.notification'
    target.velocity 'fadeOut',
      complete: =>
        target.remove()
        return
    return

  $('#btn-menu').on 'click', ->
    $('.hamburger', $(@)).toggleClass 'active'
    $('.overlay-menu').toggleClass 'active'

  $('.slider-left').on 'click', (e) ->
    btn = $(@)
    target = $(btn.attr 'data-target')

    target.data('slidea').prev()
    return

  $('.slider-right').on 'click', (e) ->
    btn = $(@)
    target = $(btn.attr 'data-target')

    target.data('slidea').next()
    return

  # Initialize slidea plugin
  #
  $('#slidea-spot').slidea
    width: 1920
    height: 1275
    autoplay: false
    layout: "fluid"
    parallax: false
    controls: false
    progress: false
    content_scaling: true
    content_parallax: false
    grid:
      rows: 4
      columns: 4
      stagger: 50
    overlap: 0 # SET TO 0 FOR OVERLAPPING
    delay: 7000
    animation:
      in: 'perspectiveRightIn, duration 1000'
      out: 'perspectiveLeftOut, duration 1000'

  # Initialize slidea plugin
  #
  $('#slidea-advertisement').slidea
    width: 3264
    height: 600
    autoplay: true
    layout: "fluid"
    parallax: false
    controls: false
    progress: false
    responsive_font: true
    responsive_font_all: true
    delay: 5000
    overlap: 0
    touch: false

  # Initialize slidea plugin
  #
  $('#slidea').slidea
    width: 1920
    height: 1275
    autoplay: false
    layout: "default"
    parallax: false
    content_scaling: true
    content_parallax: false
    progress: false
    grid:
      rows: 4
      columns: 4
      stagger: 50
    overlap: 0 # SET TO 0 FOR OVERLAPPING
    delay: 7000
    animation:
      in: 'perspectiveRightIn, duration 1000'
      out: 'perspectiveLeftOut, duration 1000'

  return
